import React,{useState,useEffect} from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/SimpleFiveColumn.js";
import MainBanner from "components/hero/AurumHeroContent.js";
import BlogMainContent from "components/hero/BlogMainContent.js";
import Stats from "components/features/ThreeColCenteredStatsPrimaryBackground.js";
import OurExpertise from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
import Features from "components/features/ThreeColWithSideImage.js";
import FeatureWithSteps from "components/features/TwoColWithSteps.js";
import Testimonials from "components/testimonials/TwoColumnWithImageAndRating.js";
import TrackRecord from "components/features/TwoColSingleFeatureWithStats2.js";
import Faqs from "components/faqs/SimpleWithSideImage.js";
import StrongBrandImage from "images/aurum_Icons/uiuxImage.svg";
import macHeroScreenshotImageSrc from "images/hero-screenshot-2.png";
import { Subheading as SubheadingBase } from "components/misc/Headings.js";
import { ReactComponent as Checkicon } from "../images/check.svg";
import BlogSummary from "components/cards/BlogSummary.js";
import beckTesting from "images/aurum_Icons/becktesting.png";
import PreFooter from "components/footers/PreFooterBrand.js";
import CaseStudy from "components/testimonials/CaseStudyTextOnly.js";
import caseStudyLogoImage from "images/clientLogos/languagePantheon.png";
import caseStudyProfileImage from "images/lp_anuj_k_acharya.png"
import ReactPlayer from "react-player"; 
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
  useParams,
} from "react-router-dom";
import { Helmet } from 'react-helmet';
import {serverUtilities} from '../services/serverutilities.service';
import axios from "axios";
const SubheadingSteps = tw.span`uppercase tracking-widest font-bold text-blue-900`;
const HighlightedText = tw.span`text-blue-900 `;

// const VideoWrapper = styled.div`
//   ${tw`flex justify-center items-center w-full h-full p-4`}
//   // z-index: 10;
//   position: relative;
// `;


const BoldTag = styled.span`
   ${tw`text-textBlack font-bold`}
`;
export default ({
 title = "Simplified On-Boarding",
 highlightedtext = "Get Started.",
 heading = "Easy to",
 CheckIcon = Checkicon,
}) => { 
const url = window.location.href;
//console.log(url);
//alert(url);
let spliturl= url.split('/').pop();
let lhpEx = spliturl.indexOf('lhp-');
if(lhpEx>0)
  spliturl = spliturl.split('lhp-').pop();

//alert (spliturl)
let titleBlog = spliturl.replace(/-/g, " ");
titleBlog=titleBlog.replace(/\b\w/g, x => x.toUpperCase())
//alert(spliturl);
console.log(spliturl);
const [blogData,setBlogData] = useState('0');
const [relatedblogData,setrelatedBlogData] = useState('0');
const [blogProds, setBlogProds] = useState("0");
let vals = '0';
let slug = url.name;

const URI ="https://happyness.pro/tt/"+spliturl;
 const requestOptions = {
      method: 'GET',
      headers: {}
  };
  // const res =  axios.get(URI);
  //console.log('33333333333',res); 

  // let blogData = res.data.vals;
  // console.log("blogData==",blogData)
  // let relatedblogData = res.related;
  //console.log("res==",res);
  //let blogData = '0';
  //let relatedblogData = '0';
  useEffect(() => {
  if (blogData == '0')
  {
  const res =  axios.get(URI);
  console.log(res); 
  const bD = res.then(data => {
    console.log('555555555',data.data.vals);
    setBlogData(data.data.vals);
    setrelatedBlogData(data.data.related)
    //blogData = data.data.vals;
    //relatedblogData = data.data.related;
    console.log(data.data.related);
    let v = data.data.vals[0].id + "--" + data.data.vals[0].keywords.replace(" ","-");
    v = v.toLowerCase();
    serverUtilities.getBlogProds(v.toString()).then((resData) => {
      console.log(resData);
      //console.log(resData.vals.vals);
      if (resData) {
        console.log('11111111',resData.prods)
        setBlogProds(resData.prods);
        //titleBlog = resData.vals.title;
        // setBlogBanners(resData.banners);
        //val = resData.status;
      }
    });
  });
  console.log('blogData',blogData);
}
}, []);






//alert ("1");
 /*let slug = url.name;*/
// useEffect(()=>{
//   if(spliturl!=undefined && spliturl!=''){
//    serverUtilities.getBlog(spliturl).then(resData=>{
//         //console.log(resData.vals.vals);
//         if(resData.status=='1'){
//           setBlogData(resData.vals);
//           //titleBlog = resData.vals.title;
//           setrelatedBlogData(resData.related)
//           //val = resData.status;
//         }
//       });
//  }

// },[])
// console.log(blogData);
//console.log(vals);
// if ((vals == '0') && (blogData!=null))
// {}
// else
return (

    
    blogData != "0" &&
    relatedblogData != "0" && (
     
   
    <>

    <Helmet
        title={(titleBlog!=null && titleBlog)}
        meta={[
          {
            name: "description",
            content:
              (blogData!=null && blogData[0].descrip),
          },
          {
            property: `og:title`,
            content: (blogData!=null && blogData[0].title) ? blogData[0].title:"HAPPYNESS PRO - Rule Based Trading",
          },
          {
            property: `og:description`,
            content: (blogData!=null && blogData[0].descrip) ? blogData[0].descrip :"HappyNess Pro - Rule Based Trading platforms where you can get parallel income",
          },
          {
            property: `og:image`,
            content: "https://aurumfiles.b-cdn.net/hp_logo.png",
          },
          {
            property: `og:image:alt`,
            content: "HappyNess Pro",
          },
          {
            property: `og:image:width`,
            content: "500",
          },
          {
            property: `og:image:height`,
            content: "500",
          },
          {
            property: `og:url`,
            content: url ? url:"https://happyness.pro/blog",
          }
        ]}
    />
          
    <AnimationRevealPage>
  

      <BlogMainContent roundedHeaderButton={true} 
        mainTitle={blogData!=null && blogData[0].title}
        subTitle={blogData!=null && blogData[0].descrip}
        buttonText="Let's Talk"
        buttonUrl="https://wa.me/918920403902/?text=Hi, I would like to GROW my Business with Aurum Content Delivery Platform."
        imageSrc={beckTesting}
        showClient= {false}
        />
      <BlogSummary heading="Happyness Trading Platform" subheading="" description="Everything You Need to Deliver & Monetise Content " data={blogData!=null && blogData[0].html} relatedData={relatedblogData!=null && relatedblogData}   blogProds={blogProds != null && blogProds}
       />

      <PreFooter fromPage = "Latest Updates" formheading = "Kickstart your journey with rule based trading here." formsubheading = "Book Our Free Discussion Session Today - " heightlightText="The Benefits of Using a Structured Content Distribution Platform"/>
      <Footer />
    </AnimationRevealPage>
    </>
    )
    
  );
};
