import React from 'react';
import { useParams } from 'react-router-dom';
import AnimationRevealPage from "helpers/AnimationRevealPage.js"

import EventLandingPage from "demos/EventLandingPage.js";
import HotelTravelLandingPage from "demos/HotelTravelLandingPage.js";
import AgencyLandingPage from "demos/AgencyLandingPage.js";
import SaaSProductLandingPage from "demos/SaaSProductLandingPage.js";
import RestaurantLandingPage from "demos/RestaurantLandingPage.js";
import ServiceLandingPage from "demos/ServiceLandingPage.js";
import HostingCloudLandingPage from "demos/HostingCloudLandingPage.js";

import EventLandingPageImageSrc from "images/demo/EventLandingPage.jpeg";
import HotelTravelLandingPageImageSrc from "images/demo/HotelTravelLandingPage.jpeg";
import AgencyLandingPageImageSrc from "images/demo/AgencyLandingPage.jpeg";
import SaaSProductLandingPageImageSrc from "images/demo/SaaSProductLandingPage.jpeg";
import RestaurantLandingPageImageSrc from "images/demo/RestaurantLandingPage.jpeg";
import ServiceLandingPageImageSrc from "images/demo/ServiceLandingPage.jpeg";
import HostingCloudLandingPageImageSrc from "images/demo/HostingCloudLandingPage.jpeg";

import Careers from "pages/Careers.js";
import WhyYoungMinds from "pages/WhyYoungMinds.js";
import VideoStreamingApi from "pages/ContentStreamingApi.js";
import SecuredVideoStreaming from "pages/SecuredVideoStreaming.js";
import ContentDeliveryPlatform from "pages/ContentDeliveryPlatform.js";
import AboutStrategy from "pages/AboutStrategy.js";
import HowToDeploy from "pages/HowToDeploy.js";
import YoungB1 from "pages/YoungB1.js";
import StudyInGermany from "pages/StudyInGermany.js";
import germana1 from "pages/germana1.js";
import germana2 from "pages/germana2.js";
import generaltermsandcond from "pages/generaltermsandcond.js"
import GermanLanguageForClass7 from "pages/GermanLanguageForClass7.js";
import GermanLanguageForClass8 from "pages/GermanLanguageForClass8.js";
import GermanLanguageForClass9 from "pages/GermanLanguageForClass9.js";
import GermanLanguageForClass10 from "pages/GermanLanguageForClass10.js";
import GermanLanguageForClass11 from "pages/GermanLanguageForClass11.js";
import GermanLanguageForClass12 from "pages/GermanLanguageForClass12.js";
import GermanSchoolLevelProgram from "pages/GermanSchoolLevelProgram.js";
import AboutClass8GermanCoaching from "pages/AboutClass8GermanCoaching.js";
import AboutClass9GermanCoaching from "pages/AboutClass9GermanCoaching.js";
import AboutClass10GermanCoaching from "pages/AboutClass10GermanCoaching.js";
import AboutFitInDeutsch1 from "pages/AboutFitInDeutsch1.js";
import AboutFitInDeutsch2 from "pages/AboutFitInDeutsch2.js";
import BlogInfo from "pages/BlogInfo.js";
import BlogFeed from "pages/BlogFeed.js";
import LoginPage from "pages/Login.js";
import LogoutPage from "pages/Login.js";
import SignupPage from "pages/Signup.js";
import PricingPage from "pages/Pricing.js";
import AboutUsPage from "pages/AboutUs.js";
import ContactUs from "pages/ContactUs.js";

import Media from "pages/Media.js";
import BlogIndexPage from "pages/BlogIndex.js";
// import LatestUpdatesIndex from "pages/LatestUpdatesIndex";

import TermsOfServicePage from "pages/TermsOfService.js";
import generaltermsandconditions from "pages/generaltermsandcond.js";
import PrivacyPolicyPage from "pages/PrivacyPolicy.js";
import DetailBlog from "pages/DetailIndex.js";
import PineScript from "pages/PineScript.js";
import IiflTradingAccount from "pages/IiflTradingAccount";
import LpCaseStudy from "pages/CaseStudies/lpCaseStudy.js";
import LanguageCaseStudy from "pages/CaseStudies/languageCaseStudy.js";
import CustomisedApps from "pages/GermanPedagogy.js";
import NiftyIntradayTable from "pages/NiftyIntradayLevelsUsingTechnicalAnalysis.js";
import GermanLanguagePedagogy from "pages/GermanLanguagePedagogy.js";
import VideoStreaming from "pages/GermanLp.js";
import GermanByLanguagePantheon from "pages/GermanByLanguagePantheon.js";
import LiveVideoStreaming from "pages/LiveVideoStreaming.js";
import videoondemand from "pages/videoondemand.js";
import AurumApps from "pages/AurumApps.js";
import PolicyCenter from "pages/PolicyCenter.js";
import SiteMap from "pages/SiteMap.js";

import LoginPageImageSrc from "images/demo/LoginPage.jpeg";
import SignupPageImageSrc from "images/demo/SignupPage.jpeg";
import PricingPageImageSrc from "images/demo/PricingPage.jpeg";
import AboutUsPageImageSrc from "images/demo/AboutUsPage.jpeg";
import ContactUsPageImageSrc from "images/demo/ContactUsPage.jpeg";
import BlogIndexPageImageSrc from "images/demo/BlogIndexPage.jpeg";
import TermsOfServicePageImageSrc from "images/demo/TermsOfServicePage.jpeg";
import PrivacyPolicyPageImageSrc from "images/demo/PrivacyPolicyPage.jpeg";
import BlogDetails from "pages/CaseStudies/lpCaseStudy.js";


// aurum Useful Components begins
import MainPageHero from "components/hero/AurumHeroContent.js";
// aurum Useful Components ends


import BackgroundAsImageHero from "components/hero/BackgroundAsImage.js";
import IllustrationAndVideoHero from "components/hero/TwoColumnWithVideo.js";
import IllustrationAndInputHero from "components/hero/TwoColumnWithInput.js";
import FeaturesAndTestimonialHero from "components/hero/TwoColumnWithFeaturesAndTestimonial.js";
import FullWidthWithImageHero from "components/hero/FullWidthWithImage.js";
import BackgroundAsImageWithCenteredContentHero from "components/hero/BackgroundAsImageWithCenteredContent.js";
import IllustrationAndPrimaryBackgroundHero from "components/hero/TwoColumnWithPrimaryBackground.js";

import TwoPlansWithDurationSwitcherPricing from "components/pricing/TwoPlansWithDurationSwitcher.js";
import ThreePlansWithHalfPrimaryBackgroundPricing from "components/pricing/ThreePlansWithHalfPrimaryBackground.js";
import ThreePlansPricing from "components/pricing/ThreePlans.js";

import ThreeColWithSideImageFeatures from "components/features/ThreeColWithSideImage.js";
import TwoColWithButtonFeatures from "components/features/TwoColWithButton.js";
import ThreeColSimpleFeatures from "components/features/ThreeColSimple.js";
import ThreeColWithSideImageWithPrimaryBackgroundFeatures from "components/features/ThreeColWithSideImageWithPrimaryBackground.js";
import TwoColVerticalWithButtonFeatures from "components/features/TwoColWithTwoFeaturesAndButtons.js";
import TwoColHorizontalWithButtonFeatures from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
import WithStepsAndImageFeatures from "components/features/TwoColWithSteps.js";
import ThreeColumnDashedBorderFeatures from "components/features/DashedBorderSixFeatures";
import ThreeColCenteredStatsWithPrimaryBackgroundFeatures from "components/features/ThreeColCenteredStatsPrimaryBackground.js";
import WithStatsAndImageFeatures from "components/features/TwoColSingleFeatureWithStats.js";
import WithStatsAndImage2Features from "components/features/TwoColSingleFeatureWithStats2.js";
import VerticalWithAlternateImageAndTextFeatures from "components/features/VerticalWithAlternateImageAndText.js";

import SliderCards from "components/cards/ThreeColSlider.js";
import TrendingCards from "components/cards/TwoTrendingPreviewCardsWithImage.js";
import PortfolioCards from "components/cards/PortfolioTwoCardsWithImage.js";
import TabGridCards from "components/cards/TabCardGrid.js";
import ProfileThreeColGridCards from "components/cards/ProfileThreeColGrid.js"
import ThreeColContactDetailsCards from "components/cards/ThreeColContactDetails.js"

import ThreeColSimpleWithImageBlog from "components/blogs/ThreeColSimpleWithImage.js";
import ThreeColSimpleWithImageAndDashedBorderBlog from "components/blogs/ThreeColSimpleWithImageAndDashedBorder.js";
import PopularAndRecentPostsBlog from "components/blogs/PopularAndRecentBlogPosts.js";
import GridWithFeaturedPostBlog from "components/blogs/GridWithFeaturedPost.js";

import TwoColumnWithImageTestimonial from "components/testimonials/TwoColumnWithImage.js";
import TwoColumnWithImageAndProfilePictureReviewTestimonial from "components/testimonials/TwoColumnWithImageAndProfilePictureReview.js";
import TwoColumnWithImageAndRatingTestimonial from "components/testimonials/TwoColumnWithImageAndRating.js";
import ThreeColumnWithProfileImageTestimonial from "components/testimonials/ThreeColumnWithProfileImage.js";
import SimplePrimaryBackgroundTestimonial from "components/testimonials/SimplePrimaryBackground.js";

import SimpleWithSideImageFAQS from "components/faqs/SimpleWithSideImage.js";
import SingleColFAQS from "components/faqs/SingleCol.js";
import TwoColumnPrimaryBackgroundFAQS from "components/faqs/TwoColumnPrimaryBackground.js";

import SimpleContactUsForm from "components/forms/SimpleContactUs.js";
import SimpleSubscribeNewsletterForm from "components/forms/SimpleSubscribeNewsletter.js";
import TwoColContactUsForm from "components/forms/TwoColContactUsWithIllustration.js";
import TwoColContactUsFullForm from "components/forms/TwoColContactUsWithIllustrationFullForm.js";

import GetStartedCTA from "components/cta/GetStarted.js";
import GetStartedLightCTA from "components/cta/GetStartedLight.js";
import DownloadAppCTA from "components/cta/DownloadApp.js";

import SimpleFiveColumnFooter from "components/footers/SimpleFiveColumn.js";
import FiveColumnWithInputFormFooter from "components/footers/FiveColumnWithInputForm.js";
import FiveColumnWithBackgroundFooter from "components/footers/FiveColumnWithBackground.js";
import FiveColumnDarkFooter from "components/footers/FiveColumnDark.js";
import MiniCenteredFooter from "components/footers/MiniCenteredFooter.js";
import LatestUpdatesIndex from 'pages/LatestUpdatesIndex';
import ContactUsForAccountOpening from 'pages/ContactUsForAccountOpening';


export const components = {
  landingPages: {
    RestaurantLandingPage: {
      component: RestaurantLandingPage,
      imageSrc: RestaurantLandingPageImageSrc,
      url: "/landingPages/RestaurantLandingPage",
    },
    HotelTravelLandingPage: {
      component: HotelTravelLandingPage,
      imageSrc: HotelTravelLandingPageImageSrc,
      url: "/landingPages/HotelTravelLandingPage",
    },
    SaaSProductLandingPage: {
      component: SaaSProductLandingPage,
      imageSrc: SaaSProductLandingPageImageSrc,
      url: "/landingPages/SaaSProductLandingPage",
    },
    ServiceLandingPage: {
      component: ServiceLandingPage,
      imageSrc: ServiceLandingPageImageSrc,
      url: "/landingPages/ServiceLandingPage",
    },
    EventLandingPage: {
      component: EventLandingPage,
      imageSrc: EventLandingPageImageSrc,
      url: "/landingPages/EventLandingPage",
    },
    AgencyLandingPage: {
      component: AgencyLandingPage,
      imageSrc: AgencyLandingPageImageSrc,
      url: "/landingPages/AgencyLandingPage",
    },
    HostingCloudLandingPage: {
      component: HostingCloudLandingPage,
      imageSrc: HostingCloudLandingPageImageSrc,
      url: "/landingPages/HostingCloudLandingPage",
    },
  },

  innerPages: {
    login: {
      component: LoginPage,
      imageSrc: LoginPageImageSrc,
      scrollAnimationDisabled: true,
      url: "/login",
    },
    generaltermsandconditions: {
      component: generaltermsandcond,
      url: "/general-terms-and-conditions",
    },
    germana1: {
      component: germana1,
      scrollAnimationDisabled: true,
      url: "/german-a1",
    },
    germana2: {
      component: germana2,
      scrollAnimationDisabled: true,
      url: "/german-a2",
    },
    LogoutPage: {
      component: LogoutPage,
      imageSrc: LoginPageImageSrc,
      scrollAnimationDisabled: true,
      url: "/LogoutPage",
    },
    whyyoungminds: {
      component: WhyYoungMinds,
      scrollAnimationDisabled: true,
      url: "/why-young-minds",
    },
    contentdeliveryplatform: {
      component: ContentDeliveryPlatform,
      scrollAnimationDisabled: true,
      url: "/content-delivery-platform",
    },
    videostreamingapi: {
      component: VideoStreamingApi,
      scrollAnimationDisabled: true,
      url: "/video-streaming-api",
    },
    securedvideostreaming: {
      component: SecuredVideoStreaming,
      scrollAnimationDisabled: true,
      url: "/secured-video-streaming",
    },
    careers: {
      component: Careers,
      scrollAnimationDisabled: true,
      url: "/careers",
    },
    sitemap: {
      component: SiteMap,
      scrollAnimationDisabled: true,
      url: "/site-map",
    },
    policycenter: {
      component: PolicyCenter,
      scrollAnimationDisabled: true,
      url: "/policy-center",
    },
    prospectsofgermanlanguage: {
      component: GermanLanguagePedagogy,
      scrollAnimationDisabled: true,
      url: "/prospects-of-Proffie-offerings",
    },
    customisedapps: {
      component: CustomisedApps,
      scrollAnimationDisabled: true,
      url: "/customised-apps",
    },
    aurumapps: {
      component: AurumApps,
      scrollAnimationDisabled: true,
      url: "/aurum-apps",
    },
    germanbylanguagepantheon: {
      component: GermanByLanguagePantheon,
      scrollAnimationDisabled: true,
      url: "/Proffie-offerings",
    },
    videostreaming: {
      component: VideoStreaming,
      scrollAnimationDisabled: true,
      url: "/video-streaming",
    },
    livevideostreaming: {
      component: LiveVideoStreaming,
      scrollAnimationDisabled: true,
      url: "/live-video-streaming",
    }
    ,
    videoondemand: {
      component: videoondemand,
      scrollAnimationDisabled: true,
      url: "/video-on-demand",
    }
    ,

    iifltradingaccount: {
      component: IiflTradingAccount,
      scrollAnimationDisabled: true,
      url: "/iifl-trading-account",
    }
    ,
    signup: {
      component: SignupPage,
      url: `/signup`,
      imageSrc: SignupPageImageSrc,
      scrollAnimationDisabled: true,
    },
    PricingPage: {
      component: PricingPage,
      url: `/innerPages/PricingPage`,
      imageSrc: PricingPageImageSrc,
    },
    aboutus: {
      component: AboutUsPage,
      url: `/about-us`,
      imageSrc: AboutUsPageImageSrc,
    },
    contactus: {
      component: ContactUs,
      url: `/contact-us`,
      imageSrc: ContactUsPageImageSrc,
    },
    contactusforaccountopening: {
      component: ContactUsForAccountOpening,
      url: `/contact-us-for-account-opening`,
      imageSrc: ContactUsPageImageSrc,
    },
    media: {
      component: Media,
      url: `/media`,
      // imageSrc: ContactUsPageImageSrc,
    },
    // blog: {
    //   component: BlogIndexPage,
    //   url: `/blog`,
    //   imageSrc: BlogIndexPageImageSrc,
    // },
    aboutrulebasedalgotrading: {
      component: AboutStrategy,
      scrollAnimationDisabled: true,
      url: "/about-rule-based-algo-trading",
    },
    howtodeploystrategyinyourtradingaccount: {
      component: HowToDeploy,
      scrollAnimationDisabled: true,
      url: "/how-to-deploy-strategy-in-your-trading-account",
    },
    germanlanguageyoungb1: {
      component: YoungB1,
      scrollAnimationDisabled: true,
      url: "/german-language-young-b1",
    },
    studyingermany: {
      component: StudyInGermany,
      scrollAnimationDisabled: true,
      url: "/study-in-germany",
    },
    germanlanguageforclass7: {
      component: GermanLanguageForClass7,
      scrollAnimationDisabled: true,
      url: "/german-language-for-class-7",
    },
    germanlanguageforclass8: {
      component: GermanLanguageForClass8,
      scrollAnimationDisabled: true,
      url: "/german-language-for-class-8",
    },
    germanlanguageforclass9: {
      component: GermanLanguageForClass9,
      scrollAnimationDisabled: true,
      url: "/german-language-for-class-9",
    },
    germanlanguageforclass10: {
      component: GermanLanguageForClass10,
      scrollAnimationDisabled: true,
      url: "/german-language-for-class-10",
    },
    germanlanguageforclass11: {
      component: GermanLanguageForClass11,
      scrollAnimationDisabled: true,
      url: "/german-language-for-class-11",
    },
    germanlanguageforclass12: {
      component: GermanLanguageForClass12,
      scrollAnimationDisabled: true,
      url: "/german-language-for-class-12",
    },
    germanschoollevelprogram: {
      component: GermanSchoolLevelProgram,
      scrollAnimationDisabled: true,
      url: "/german-school-level-program",
    },
    aboutclass8germancoaching: {
      component: AboutClass8GermanCoaching,
      scrollAnimationDisabled: true,
      url: "/about-class-8-german-coaching",
    },
    aboutclass9germancoaching: {
      component: AboutClass9GermanCoaching,
      scrollAnimationDisabled: true,
      url: "/about-class-9-german-coaching",
    },
    aboutclass10germancoaching: {
      component: AboutClass10GermanCoaching,
      scrollAnimationDisabled: true,
      url: "/about-class-10-german-coaching",
    },
    aboutfitindeutsch1: {
      component: AboutFitInDeutsch1,
      scrollAnimationDisabled: true,
      url: "/about-fit-in-deutsch-1",
    },
    upcomingseminar: {
      component: AboutFitInDeutsch2,
      scrollAnimationDisabled: true,
      url: "/upcoming-seminar",
    },
    blog: {
      component: BlogIndexPage,
      url: `/blog`,
      imageSrc: BlogIndexPageImageSrc,
    },
    bloginfo: {
      component: BlogInfo,
      scrollAnimationDisabled: true,
      url: "/blog-info",
    },
    blogfeed: {
      component: BlogFeed,
      scrollAnimationDisabled: true,
      url: "/blog-feed",
    },
    blogdetails: {
      component: BlogIndexPage,
      scrollAnimationDisabled: true,
      url: "/blog",
    },
    learnershub: {
      component: BlogIndexPage,
      scrollAnimationDisabled: true,
      url: "/learners-hub",
    },
    termsofservicepage: {
      component: TermsOfServicePage,
      url: `/terms-of-service-page`,
      imageSrc: TermsOfServicePageImageSrc,
    },
    generaltermsandconditions: {
      component: generaltermsandconditions,
      url: `/general-terms-and-conditions`,
      imageSrc: TermsOfServicePageImageSrc,
    },
    privacypolicypage: {
      component: PrivacyPolicyPage,
      url: `/privacy-policy-page`,
      imageSrc: PrivacyPolicyPageImageSrc,
    },
    latestupdates: {
      component: BlogIndexPage,
      scrollAnimationDisabled: true,
      url: "/blog",
    },
     detailblog: {
      component: DetailBlog,
      scrollAnimationDisabled: true,
      url: "/detail-blog",
    },
    pinescript: {
      component: PineScript,
      scrollAnimationDisabled: true,
      url: "/pinescript",
    },
    niftyintradaylevelsusingtechnicalanalysis: {
      component: NiftyIntradayTable,
      scrollAnimationDisabled: true,
      url: "/nifty-intraday-levels-using-technical-analysis",
    },
    bankniftyintradaylevelsusingtechnicalanalysis: {
      component: NiftyIntradayTable,
      scrollAnimationDisabled: true,
      url: "/banknifty-intraday-levels-using-technical-analysis",
    },
    
  },

  blocks: {
    Hero: {
      type: "Hero Section",
      elements: {
        AurumHero:{
          name: "With Image Illustration and Input",
          component: MainPageHero,
          url: "/components/blocks/Hero/AurumHero",
        },
        BackgroundAsImage: {
          name: "With Background Image",
          component: BackgroundAsImageHero,
          url: "/components/blocks/Hero/BackgroundAsImage",
        },
        IllustrationAndInput: {
          name: "With Image Illustration and Input",
          component: IllustrationAndInputHero,
          url: "/components/blocks/Hero/IllustrationAndInput",
        },
        IllustrationAndVideo: {
          name: "With Image Illustration and Video",
          component: IllustrationAndVideoHero,
          url: "/components/blocks/Hero/IllustrationAndVideo",
        },
        FeaturesAndTestimonial: {
          name: "With Features And Customer Testimonial",
          component: FeaturesAndTestimonialHero,
          url: "/components/blocks/Hero/FeaturesAndTestimonial",
        },
        FullWidthWithImage: {
          name: "Full Width With Image",
          component: FullWidthWithImageHero,
          url: "/components/blocks/Hero/FullWidthWithImage",
        },
        BackgroundAsImageWithCenteredContent: {
          name: "Full Width Background Image with centered content",
          component: BackgroundAsImageWithCenteredContentHero,
          url: "/components/blocks/Hero/BackgroundAsImageWithCenteredContent",
        },
        IllustrationAndPrimaryBackground: {
          name: "Primary Background With Illustration",
          component: IllustrationAndPrimaryBackgroundHero,
          url: "/components/blocks/Hero/IllustrationAndPrimaryBackground",
        },
      }
    },
    Pricing: {
      type: "Pricing Section",
      elements: {
        TwoPlansWithDurationSwitcher: {
          name: "Two Plans With Duration Switcher",
          component: TwoPlansWithDurationSwitcherPricing,
          url: "/components/blocks/Pricing/TwoPlansWithDurationSwitcher",
        },
        ThreePlansWithHalfPrimaryBackground: {
          name: "Three Plans With Primary Background at Top",
          component: ThreePlansWithHalfPrimaryBackgroundPricing,
          url: "/components/blocks/Pricing/ThreePlansWithHalfPrimaryBackground",
        },
        ThreePlans: {
          name: "Simple Three Plans",
          component: ThreePlansPricing,
          url: "/components/blocks/Pricing/ThreePlans",
        },
      }
    },
    Features: {
      type: "Features Section",
      elements: {
        ThreeColWithSideImage: {
          name: "Three Column With Side Image",
          component: ThreeColWithSideImageFeatures,
          url: "/components/blocks/Features/ThreeColWithSideImage",
        },
         TwoColWithButton: {
          name: "Two Column With Image and Action Button",
          component: TwoColWithButtonFeatures,
          url: "/components/blocks/Features/TwoColWithButton",
        },
        ThreeColSimple: {
          name: "Three Column Simple",
          component: ThreeColSimpleFeatures,
          url: "/components/blocks/Features/ThreeColSimple",
        },
        ThreeColWithSideImageWithPrimaryBackground: {
          name: "Three Column With Side Image With Primary Background",
          component: ThreeColWithSideImageWithPrimaryBackgroundFeatures,
          url: "/components/blocks/Features/ThreeColWithSideImageWithPrimaryBackground",
        },
        TwoColHorizontalWithButton: {
          name: "Two Column With Button and Horizonatal Features with Icon",
          component: TwoColHorizontalWithButtonFeatures,
          url: "/components/blocks/Features/TwoColHorizontalWithButton",
        },
        TwoColVerticalWithButton: {
          name: "Two Column With Vertical Features and Button",
          component: TwoColVerticalWithButtonFeatures,
          url: "/components/blocks/Features/TwoColVerticalWithButton",
        },
        WithStepsAndImage: {
          name: "Steps with Image",
          component: WithStepsAndImageFeatures,
          url: "/components/blocks/Features/WithStepsAndImage",
        },
        ThreeColumnDashedBorder: {
          name: "Three Column With Dashed Primary Border",
          component: ThreeColumnDashedBorderFeatures,
          url: "/components/blocks/Features/ThreeColumnDashedBorder",
        },
        ThreeColCenteredStatsPrimaryBackground: {
          name: "Three Column With Centered Stats on Primary Background",
          component: ThreeColCenteredStatsWithPrimaryBackgroundFeatures,
          url: "/components/blocks/Features/ThreeColCenteredStatsPrimaryBackground",
        },
        WithStatsAndImage: {
          name: "Stats With Image",
          component: WithStatsAndImageFeatures,
          url: "/components/blocks/Features/WithStatsAndImage",
        },
        WithStatsAndImage2: {
          name: "Stats With Image 2",
          component: WithStatsAndImage2Features,
          url: "/components/blocks/Features/WithStatsAndImage2",
        },
        VerticalWithAlternateImageAndText: {
          name: "Vertical Feature Cards With Alternate Image and Text",
          component: VerticalWithAlternateImageAndTextFeatures,
          url: "/components/blocks/Features/VerticalWithAlternateImageAndText",
        },
      }
    },

    Cards: {
      type: "Cards",
      elements: {
        Slider: {
          name: "Three Column Slider",
          component: SliderCards,
          url: "/components/blocks/Cards/Slider",
        },
        Portfolio: {
          name: "Two Column Portfolio Cards With Images ",
          component: PortfolioCards,
          url: "/components/blocks/Cards/Portfolio",
        },
        TabGrid: {
          name: "Tab Card Grid With Tab Switcher",
          component: TabGridCards,
          url: "/components/blocks/Cards/TabGrid",
        },
        ProfileThreeColGrid: {
          name: "Three Column Grid Cards For Profile",
          component: ProfileThreeColGridCards,
          url: "/components/blocks/Cards/ProfileThreeColGrid",
        },
        ThreeColContactDetails: {
          name: "Three Column Contact Details Cards",
          component: ThreeColContactDetailsCards,
          url: "/components/blocks/Cards/ThreeColContactDetails",
        },
        Trending: {
          name: "Two Trending Preview Cards With Images",
          component: TrendingCards,
          url: "/components/blocks/Cards/Trending",
        },
      }
    },

    Blog: {
      type: "Blog Section",
      elements: {
        GridWithFeaturedPost: {
          name: "Grid With Featured Post",
          component: GridWithFeaturedPostBlog,
          url: "/components/blocks/Blog/GridWithFeaturedPost",
        },
        PopularAndRecentPosts: {
          name: "Popular And Recent Posts",
          component: PopularAndRecentPostsBlog,
          url: "/components/blocks/Blog/PopularAndRecentPosts",
        },
        ThreeColSimpleWithImage: {
          name: "Simple Three Column With Image",
          component: ThreeColSimpleWithImageBlog,
          url: "/components/blocks/Blog/ThreeColSimpleWithImage",
        },
        ThreeColSimpleWithImageAndDashedBorder: {
          name: "Simple Three Column With Image and Dashed Border",
          component: ThreeColSimpleWithImageAndDashedBorderBlog,
          url: "/components/blocks/Blog/ThreeColSimpleWithImageAndDashedBorder",
        },
      } 
    },

    Testimonial: {
      type: "Testimonial Section",
      elements: {
        TwoColumnWithImage: {
          name: "Two Column With Image",
          component: TwoColumnWithImageTestimonial,
          url: "/components/blocks/Testimonial/TwoColumnWithImage",
        },
        TwoColumnWithImageAndProfilePictureReview: {
          name: "Two Column With Image And Profile Picture Review",
          component: TwoColumnWithImageAndProfilePictureReviewTestimonial,
          url: "/components/blocks/Testimonial/TwoColumnWithImageAndProfilePictureReview",
        },
        TwoColumnWithImageAndRating: {
          name: "Two Column With Image And Rating",
          component: TwoColumnWithImageAndRatingTestimonial,
          url: "/components/blocks/Testimonial/TwoColumnWithImageAndRating",
        },
        ThreeColumnWithProfileImage: {
          name: "Three Column With Profile Image",
          component: ThreeColumnWithProfileImageTestimonial,
          url: "/components/blocks/Testimonial/ThreeColumnWithProfileImage",
        },
        SimplePrimaryBackground: {
          name: "Simple With Primary Background",
          component: SimplePrimaryBackgroundTestimonial,
          url: "/components/blocks/Testimonial/SimplePrimaryBackground",
        },
      }
    },

    FAQS: {
      type: "FAQs Section",
      elements: {
        SimpleWithSideImage: {
          name: "Simple With Side Image",
          component: SimpleWithSideImageFAQS,
          url: "/components/blocks/FAQS/SimpleWithSideImage",
        },
        SingleCol: {
          name: "Single Column",
          component: SingleColFAQS,
          url: "/components/blocks/FAQS/SingleCol",
        },
        TwoColumnPrimaryBackground: {
          name: "Two Column With Primary Background",
          component: TwoColumnPrimaryBackgroundFAQS,
          url: "/components/blocks/FAQS/TwoColumnPrimaryBackground",
        },
      }
    },

    Form: {
      type: "Forms Section",
      elements: {
        SimpleContactUs: {
          name: "Simple Contact Us",
          component: SimpleContactUsForm,
          url: "/components/blocks/Form/SimpleContactUs",
        },
        SimpleSubscribeNewsletter: {
          name: "Simple Subscribe newsletter",
          component: SimpleSubscribeNewsletterForm,
          url: "/components/blocks/Form/SimpleSubscribeNewsletter",
        },
        TwoColContactUs: {
          name: "Two Column Contact Us",
          component: TwoColContactUsForm,
          url: "/components/blocks/Form/TwoColContactUs",
        },
        TwoColContactUsFull: {
          name: "Two Column Contact Us - Full Form",
          component: TwoColContactUsFullForm,
          url: "/components/blocks/Form/TwoColContactUsFull",
        },
      }
    },

    CTA: {
      type: "CTA Section",
      elements: {
        GetStarted: {
          name: "Get Started",
          component: GetStartedCTA,
          url: "/components/blocks/CTA/GetStarted",
        },
        GetStartedLight: {
          name: "Get Started Light",
          component: GetStartedLightCTA,
          url: "/components/blocks/CTA/GetStartedLight",
        },
        DownloadApp: {
          name: "Download App",
          component: DownloadAppCTA,
          url: "/components/blocks/CTA/DownloadApp",
        },
      }
    },

    Footer: {
      type: "Footers Section",
      elements: {
        SimpleFiveColumn: {
          name: "Simple Five Column",
          component: SimpleFiveColumnFooter,
          url: "/components/blocks/Footer/SimpleFiveColumn",
        },
        FiveColumnWithInputForm: {
          name: "Five Column With Input Form",
          component: FiveColumnWithInputFormFooter,
          url: "/components/blocks/Footer/FiveColumnWithInputForm",
        },
        FiveColumnWithBackground: {
          name: "Five Column With background",
          component: FiveColumnWithBackgroundFooter,
          url: "/components/blocks/Footer/FiveColumnWithBackground",
        },
        FiveColumnDark: {
          name: "Five Column Dark",
          component: FiveColumnDarkFooter,
          url: "/components/blocks/Footer/FiveColumnDark",
        },
        MiniCentered: {
          name: "Mini Centered Dark",
          component: MiniCenteredFooter,
          url: "/components/blocks/Footer/MiniCentered",
        },
      }
    }
  }
}

export default () => {
  const { type, subtype, name } = useParams()
  console.log("jshon:" +type);
  console.log(subtype);
  console.log(name);
  try {
    let Component = null;
    if(type === "blocks" && subtype) {
      console.log(subtype);
      Component= components[type][subtype]["elements"][name].component
      return <AnimationRevealPage disabled>
          <Component/>
        </AnimationRevealPage>
    }
    else{
      console.log(subtype);

      // if (type=='learners-hub')
      // {
      //   Component = components['innerPages']["bloginfo"].component;
      // }

      if((type === undefined && subtype === undefined) && (name != 'learners-hub')){
        //console.log("ctype " + components["innerPages"][name].component);
        
        let n2 = name.replaceAll('-daily-','-intraday-');
        //console.log(name); 
        let n1 = n2.split("-for-");        
        let nameN = n1[0].replaceAll('-', '');
        console.log(nameN);
        if (name.indexOf('lhp-')>-1)
        {
          Component = components['innerPages']["bloginfo"].component;
        }
        else
          Component = components["innerPages"][nameN].component
      }else if((type === 'blog' && name != undefined) || (name == 'learners-hub')){
        let nameN = name.replaceAll('-', '');
        console.log(nameN);
        //console.log(type);
        Component = components['innerPages']["blogdetails"].component
        console.log(Component)
      }else
      if ((type.indexOf('learners') > -1) || (type.indexOf('technical') > -1) || (type.indexOf('articles') > -1)) //=== 'german-language')
        Component = components['innerPages']["bloginfo"].component;
        else
      Component= components[type][name].component  
      
    }
      
    if(Component)
      return <Component/>

    Component = components['innerPages']["bloginfo"].component;
    console.log(Component);

     return <Component/>
    throw new Error("Unable to Find What You Are Looking For....")
  }
  catch (e) {
    console.log(e)
    return <div>Unable to Find What You Are Looking For...</div>
  }
}